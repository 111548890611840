import store from '@/store'

export const showNativeNavBar = () => {
    toggleNativeNavBar(false)
}

export const hideNativeNavBar = () => {
    toggleNativeNavBar(true)
}

const toggleNativeNavBar = (isHidden: boolean) => {
    const deviceInfo = store.getters.deviceInfo
    if (deviceInfo.runtimeType !== 'ios') {
        return
    }
    window.location.href = `/updateTabBar?isHidden=${isHidden ? 'true' : 'false'}`
}
