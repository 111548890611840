<template>
    <div>
        <validation-provider
            :mode="validationMode"
            v-slot="{ errors }"
            :vid="name"
            :rules="validation"
        >
            <div
                class="input"
                :class="containerClasses"
            >
                <label
                    v-if="label"
                    :for="name"
                >{{ label }}</label>
                <cleave
                    ref="formFieldInput"
                    type="text"
                    inputmode="decimal"
                    autocomplete="off"
                    class="form-control"
                    form-classes="form-classes"
                    :id="name"
                    :options="options"
                    :class="[errors[0] ? 'is-invalid' : null]"
                    :name="name"
                    :placeholder="placeholder"
                    :value="value"
                    v-model="cleaveValue"
                    :disabled="disabled"
                    :data-testid="dataTestid"
                />
                <div
                    class="invalid-feedback"
                    data-testid="form-field-currency-error"
                >
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    /**
     * Use for currency input
     * @desc Note: On Focus cursor position.
     *             iOS - always at the end of prefix or existing entry
     *             Android(verified from 6.0 to 12) - always at the start of input field, regardless of prefix or exiting entry
     * @prop{String} name (required) for this component
     * @prop{String} label (optional) A label above the input.
     * @prop{String} validation (optional) A string with the vee-validate rules string. default is 'required'
     * @prop{String} validationMode (optional) A string with the vee-validate validation mode. default is 'aggressive'
     * @prop{Boolean} focus (optional) To set focus on the input. Defaults to false.
     * @prop{Boolean} disabled (optional) disables input. Defaults to false.
     * @prop{Object} options (optional) An object with cleave options. cleave doc @see https://github.com/nosir/cleave.js/blob/master/doc/options.md
     * @prop{String} placeholder (optional) The text when the input is blank.
     * @prop{String} value (optional) value
     * @prop{String} formClasses (optional) A string of any classes you want to apply to the input field
     * @prop{String} containerClasses (optional) A string of any classes you want to apply to the input field's parent div
     */
    import { ValidationProvider } from 'vee-validate'
    import Cleave from 'vue-cleave-component'
    import 'cleave.js/dist/addons/cleave-phone.us'

    export default {
        name: 'FormCurrencyField',
        props: {
            name: { type: String, required: true },
            label: { type: String, default: null },
            validation: { type: String, default: 'required' },
            validationMode: {
                type: String,
                default: 'aggressive',
                validator: function (value) {
                    return ['eager', 'aggressive', 'passive', 'lazy'].indexOf(value) !== -1
                },
            },
            focus: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            options: {
                type: Object,
                default: function () {
                    return {
                        numeral: true,
                        prefix: '$',
                        noImmediatePrefix: true,
                        rawValueTrimPrefix: true,
                        numeralPositiveOnly: true,
                    }
                },
            },
            placeholder: {
                type: String,
                default: '$0.00',
            },
            formClasses: { type: String, default: null },
            containerClasses: { type: String, default: '' },
            value: { type: String, default: null },
            dataTestid: { type: String },
        },
        components: {
            'validation-provider': ValidationProvider,
            cleave: Cleave,
        },
        created() {
            this.cleaveValue = this.value
        },
        mounted() {
            if (this.focus) {
                this.$refs.formFieldInput.$el.focus()
            }
        },
        data: () => ({
            cleaveValue: null,
        }),
        watch: {
            cleaveValue(value) {
                this.$emit('input', value)
            },
        },
        methods: {
            setFocus: function () {
                this.$refs.formFieldInput.$el.focus()
            },
            clearValue: function () {
                this.cleaveValue = null
            },
        },
    }
</script>
