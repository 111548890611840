<template>
    <form-container>
        <validation-provider
            mode="eager"
            v-slot="{ errors }"
            vid="bankAccountType"
            ref="bankAccountTypeProvider"
            rules="confirmNoBusinessAccountManualCashout"
            class="form-floating"
        >
            <div class="input">
                <select
                    name="bankAccountType"
                    v-model="bankAccountInfo.aciBankAccountType"
                    :class="['form-control', { 'is-invalid': errors[0], 'is-valid': valid }]"
                >
                    <option
                        v-for="type in BankAccountTypes"
                        :key="type.value"
                        :value="type.value"
                    >
                        {{ type.name }}
                    </option>
                </select>
            </div>
            <div :class="['invalid-feedback', { 'd-block': errors[0] }]">
                {{ errors[0] }}
            </div>
        </validation-provider>

        <form-container
            v-if="bankAccountInfo.aciBankAccountType === AciAccountSubType.PERSONAL_CHECKING || bankAccountInfo.aciBankAccountType === AciAccountSubType.PERSONAL_SAVINGS"
            ref="bankAccountInformationForm"
            class="mt-3"
        >
            <img
                src="../assets/check-bottom-clipped.jpg"
                alt="Contact your bank for your routing and account number"
                class="w-100 mt-2"
            >

            <form-field
                class="mt-2"
                v-model="bankAccountInfo.routingNumber"
                input-type="tel"
                inputmode="decimal"
                ref="routing"
                name="routing"
                :validation="`required|min:${BANK_ROUTING_NUMBER_LENGTH}|max:${BANK_ROUTING_NUMBER_LENGTH}|numeric`"
                :max-length="BANK_ROUTING_NUMBER_LENGTH"
                validation-mode="aggressive"
                :placeholder="$t('pages.AddBankAccount.RoutingNumber')"
                data-testid="form-field-routing-number"
            />

            <validation-observer>
                <form-field
                    v-model="bankAccountInfo.bankAccountNumber"
                    input-type="tel"
                    inputmode="decimal"
                    name="accountNumber"
                    ref="accountNumber"
                    :validation="`required|min:${BANK_ACCOUNT_NUMBER_MIN_LENGTH}|max:${BANK_ACCOUNT_NUMBER_MAX_LENGTH}|numeric`"
                    :max-length="BANK_ACCOUNT_NUMBER_MAX_LENGTH"
                    validation-mode="aggressive"
                    :placeholder="$t('pages.AddBankAccount.AccountHolderNumber')"
                    data-testid="form-field-account-number"
                />
                <form-field
                    v-model="bankAccountInfo.confirmBankAccountNumber"
                    input-type="tel"
                    inputmode="decimal"
                    name="confirmAccountNumber"
                    ref="confirmAccountNumber"
                    :validation="`required|min:${BANK_ACCOUNT_NUMBER_MIN_LENGTH}|max:${BANK_ACCOUNT_NUMBER_MAX_LENGTH}|numeric|confirmAccountNumber:@accountNumber`"
                    :max-length="BANK_ACCOUNT_NUMBER_MAX_LENGTH"
                    validation-mode="aggressive"
                    :placeholder="$t('pages.AddBankAccount.ConfirmAccountHolderNumber')"
                    data-testid="form-field-confirm-account-number"
                />
            </validation-observer>
        </form-container>
    </form-container>
</template>

<script>
    import FormField from '@/components/base/FormField'
    import FormContainer from '@/components/base/FormContainer'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import format from '@/mixins/format'
    import { AciAccountSubType, BankAccountTypes, BANK_ROUTING_NUMBER_LENGTH, BANK_ACCOUNT_NUMBER_MAX_LENGTH, BANK_ACCOUNT_NUMBER_MIN_LENGTH } from '@/data/constants'

    export default {
        name: 'BankAccountInfoForm',
        components: {
            ValidationObserver,
            ValidationProvider,
            FormField,
            FormContainer,
        },
        mixins: [format],
        data: function () {
            return {
                BankAccountTypes,
                AciAccountSubType,
                BANK_ACCOUNT_NUMBER_MIN_LENGTH,
                BANK_ACCOUNT_NUMBER_MAX_LENGTH,
                BANK_ROUTING_NUMBER_LENGTH,
            }
        },
        props: {
            value: {
                type: Object,
                default: function () {
                    return {
                        aciBankAccountType: BankAccountTypes[0].value,
                        routingNumber: '',
                        bankAccountNumber: '',
                        confirmBankAccountNumber: '',
                    }
                },
            },
        },
        computed: {
            bankAccountInfo: {
                get() {
                    return this.value
                },
                set(bankAccountInfo) {
                    this.$emit('input', bankAccountInfo)
                },
            },
        },
    }
</script>

<style scoped></style>
