<template>
    <div>
        <div
            v-if="loading"
            class="container"
        >
            <loading-indicator
                v-if="loading"
                :title="loadingTitle"
                data-testid="loading-indicator"
            />
        </div>
        <div
            data-testid="cash-out-screen"
            v-else
        >
            <page-header
                :title="currentScreen !== Screens.cashOutSuccess ? $t('pages.cashOut.title') : null"
                :show-back="showBack"
                :show-close="showClose"
                @onClose="handleOnClose"
            />
            <div class="text-center">
                <div v-if="avenAccountStanding !== AvenAccountStanding.current">
                    <feature-not-available />
                </div>
                <div v-else>
                    <div
                        class="alert alert-warning text-start mt-2 mx-3"
                        v-if="errorText"
                        role="alert"
                        data-testid="cash-out-alert"
                    >
                        <span v-html="errorText" />
                    </div>
                    <div
                        class="alert alert-warning text-start mt-2 mx-3"
                        v-if="haveNotActivatedCardsBefore"
                        role="alert"
                        data-testid="cash-out-container"
                    >
                        <span v-html="$t('pages.cashOut.cardNotActivatedMessage', { featureName: 'Cash Out' })" />
                        <a
                            class="text-start error-link"
                            @click.prevent="onClickCardActivationLink"
                        > Activate Now </a>
                    </div>

                    <div v-if="!isPrimaryCard && !isCoApplicantCard">
                        <div class="text-muted text-center mx-2 mb-3">
                            <span v-html="$t('pages.cashOut.ineligibleSecondaryCard')" />
                        </div>
                    </div>
                    <div
                        v-else-if="currentScreen === Screens.cashOutSummary"
                        class="mb-3"
                    >
                        <!--- have transactions view --->
                        <div
                            v-if="existingCashOutTransfers && existingCashOutTransfers.size > 0"
                            data-testid="cash-out-container"
                        >
                            <div
                                v-for="[header, transactions] of existingCashOutTransfers"
                                :key="header"
                            >
                                <section-header>
                                    {{ header }}
                                </section-header>
                                <list-row
                                    v-for="LatestTransaction in transactions"
                                    :key="LatestTransaction.initiationDate"
                                    :prefix="convertDateTimeFormat(LatestTransaction.initiationDate, { outputFormat: 'MMM DD' })"
                                    :title="LatestTransaction.lender"
                                    :value="toFormattedUSD(LatestTransaction.amount)"
                                    show-arrow
                                    @onClick="handleRowClick(LatestTransaction.id)"
                                />
                                <p
                                    v-if="header === 'PENDING'"
                                    class="text-muted text-start small mb-5 mt-1 px-3 fs-6"
                                >
                                    {{ $t('pages.cashOut.pendingCashOutListSubtext') }}
                                </p>
                            </div>
                        </div>
                        <!--- no transactions view --->
                        <div
                            v-else
                            class="container text-center"
                            data-testid="cash-out-container"
                        >
                            <img
                                src="@/assets/images/pages/settings/bankNotes.svg"
                                alt="Send Cash To Your Bank"
                                class="mb-3"
                            >
                            <h5
                                class="text-center fw-light mb-1"
                                v-html="$t('pages.cashOut.emptyTitle')"
                            />
                            <p
                                class="text-muted mb-3"
                                v-html="$t('pages.cashOut.emptySubTitle')"
                            />
                        </div>
                        <div class="container d-grid mb-4">
                            <button
                                class="btn btn-primary"
                                @click="btnOnClickNewCashOut"
                                :disabled="!availableCredit || haveNotActivatedCardsBefore"
                                data-testid="new-cash-out-button"
                            >
                                {{ $t('pages.cashOut.cta') }}
                            </button>
                        </div>
                        <learn-more>
                            <div
                                v-for="[index, faq] of faqs.entries()"
                                :key="index"
                            >
                                <div class="fw-bolder mb-1">
                                    {{ faq.title }}
                                </div>
                                <div
                                    v-html="faq.content"
                                    class="mb-3"
                                />
                            </div>
                        </learn-more>
                    </div>
                    <div v-else-if="currentScreen === Screens.passwordCheck">
                        <div class="col-10 offset-1 col-md-8 offset-md-2 mt-3">
                            <h5
                                class="mb-4"
                                v-html="$t('pages.cashOut.verifyPassword')"
                            />
                            <form-container
                                class="mb-3"
                                ref="passwordForm"
                                data-testid="password-form"
                                @onSubmit="passwordVerification"
                            >
                                <form-field
                                    ref="passwordInput"
                                    v-model="password"
                                    name="password"
                                    input-type="password"
                                    class="mb-2"
                                    validation="required"
                                    validation-mode="lazy"
                                    :placeholder="$t('pages.passwordLogin.placeHolder')"
                                    data-testid="password-form-password-input"
                                />
                                <base-button
                                    type="submit"
                                    :submitting="submitting"
                                    data-testid="password-form-submit-button"
                                >
                                    {{ $t('global.cta.continue') }}
                                </base-button>
                            </form-container>
                            <base-button
                                button-classes="btn btn-tertiary"
                                @click="forgotPassword"
                                data-testid="forgot-password-button"
                            >
                                {{ $t('global.cta.forgotPassword') }}
                            </base-button>
                        </div>
                    </div>
                    <div v-else-if="currentScreen === Screens.last4SsnCheck">
                        <div class="container">
                            <h5
                                class="text-center mx-6"
                                v-html="$t('pages.cashOut.ssnVerify')"
                            />
                            <form-container
                                ref="ssnForm"
                                @onSubmit="verifyLast4Ssn"
                                data-testid="ssn-form"
                            >
                                <form-field-ssn
                                    ref="ssnField"
                                    name="last4Ssn"
                                    class="mb-3"
                                    v-model="last4Ssn"
                                    :placeholder="$t('pages.cashOut.ssnPlaceholder')"
                                    last-four-only
                                    data-testid="ssn-form-last4-input"
                                />

                                <base-button
                                    type="submit"
                                    :submitting="submitting"
                                    :disabled="last4Ssn.length !== 4"
                                    data-testid="ssn-form-submit-button"
                                >
                                    {{ $t('pages.SSNVerification.cta') }}
                                </base-button>
                            </form-container>
                        </div>
                    </div>
                    <div v-else-if="currentScreen === Screens.createCashOutForm">
                        <div class="container">
                            <div>
                                <form-container
                                    id="cashOutForm"
                                    ref="cashOutForm"
                                    v-slot="{ invalid }"
                                    data-testid="cash-out-form"
                                >
                                    <div class="text-start text-muted mb-1">
                                        {{ $t('pages.cashOut.inputLabel', { availableCredit: toFormattedUSD(maxCashOutAmountPerTransaction) }) }}
                                    </div>
                                    <form-field-currency
                                        ref="cashOutAmount"
                                        v-model="cashOutAmount"
                                        container-classes="mb-1"
                                        name="cashOutAmount"
                                        :placeholder="$t('pages.cashOut.inputPlaceholder')"
                                        :validation="`currency:1,${maxCashOutAmountPerTransaction}`"
                                        :disabled="submitting"
                                        data-testid="cash-out-form_amount-input"
                                    />
                                    <div class="text-start text-muted cash-out-limit-info">
                                        {{ $t(getCashOutLimitMessage) }}
                                    </div>
                                    <list-row
                                        style="pointer-events: none"
                                        row-icon="images/pages/settings/amountItemize.svg"
                                        :value="toFormattedUSD(cashOutWith2Percent)"
                                        value-class="fw-bold"
                                        :title="$t('pages.cashOut.cashOutText')"
                                        container-classes="bg-light mx-0 mt-2 px-3"
                                    />

                                    <p class="small text-muted text-center mt-2">
                                        {{ $t('pages.cashOut.cashOutFormSubText') }}
                                    </p>

                                    <section-header class="mt-5">
                                        {{ $t('pages.cashOut.selectCashOutAccountHeader') }}
                                    </section-header>

                                    <div>
                                        <select
                                            id="bankAccount"
                                            name="bankAccount"
                                            v-model="selectedTargetAccount"
                                            class="form-control"
                                        >
                                            <option :value="addNewBankAccountOptionId">
                                                {{ $t('pages.cashOut.addNewBankAccountOptionLabel') }}
                                            </option>
                                            <option
                                                v-for="option in targetAccountSelectOptions"
                                                :key="option.value.externalBankAccountId"
                                                :value="option.value"
                                            >
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>

                                    <div
                                        class="mt-3"
                                        v-if="selectedTargetAccount === addNewBankAccountOptionId"
                                    >
                                        <base-radio
                                            :id="AddNewBankAccountMethods.plaid"
                                            :label-right="$t('pages.cashOut.addBankAccountPlaid')"
                                            v-model="addNewBankAccountMethod"
                                            container-classes="custom-control-radio"
                                            data-testid="radio-add-bank-account-via-plaid"
                                        />
                                        <base-radio
                                            :id="AddNewBankAccountMethods.bankStatements"
                                            :label-right="$t('pages.cashOut.addBankAccountUploadStatements')"
                                            v-model="addNewBankAccountMethod"
                                            container-classes="custom-control-radio"
                                            data-testid="radio-add-bank-account-manually"
                                        />
                                        <bank-account-info-form
                                            v-if="addNewBankAccountMethod === AddNewBankAccountMethods.bankStatements"
                                            v-model="bankAccountInfo"
                                            ref="bankAccountInformationForm"
                                            class="mt-3"
                                        />
                                    </div>

                                    <base-button
                                        @click="createCashOut"
                                        class="mt-6"
                                        :submitting="submitting"
                                        button-classes="btn btn-primary"
                                        :disabled="isInputAmountEmpty || invalid"
                                        data-testid="cash-out-form_submit-button"
                                    >
                                        {{ $t('pages.cashOut.submitCashOutForm') }}
                                    </base-button>
                                </form-container>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentScreen === Screens.PickPlaidBankAccountTypeForm">
                        <div
                            class="container text-start"
                            data-testid="account-type-form"
                        >
                            <form-container
                                @onSubmit="submitPlaidBankAccountType"
                                container-classes="bg-light mx-0 mt-2 px-3"
                                ref="bankAccountTypeForm"
                            >
                                <div>
                                    <p v-html="$t('pages.cashOut.accountTypeConfirmation')" />
                                </div>
                                <div class="custom-control custom-radio d-inline-flex custom-control-tight">
                                    <input
                                        type="radio"
                                        id="PlaidAccountType.personal"
                                        name="PlaidAccountType.personal"
                                        class="custom-control-input"
                                        :value="PlaidAccountType.personal"
                                        v-model="accountType"
                                    >
                                    <label
                                        class="custom-control-label custom-control-label-tight"
                                        for="PlaidAccountType.personal"
                                    >{{ PlaidAccountType.personal }}</label>
                                </div>
                                <div class="custom-control custom-radio d-inline-flex custom-control-tight">
                                    <input
                                        type="radio"
                                        id="PlaidAccountType.business"
                                        name="PlaidAccountType.business"
                                        class="custom-control-input"
                                        :value="PlaidAccountType.business"
                                        v-model="accountType"
                                    >
                                    <label
                                        class="custom-control-label custom-control-label-tight"
                                        for="PlaidAccountType.business"
                                    >{{ PlaidAccountType.business }}</label>
                                </div>
                                <base-button
                                    class="mt-3"
                                    :submitting="submitting"
                                    button-classes="btn btn-primary"
                                    data-testid="account-type-form_submit-button"
                                >
                                    {{ $t('global.cta.continue') }}
                                </base-button>
                            </form-container>
                        </div>
                    </div>
                    <div v-else-if="currentScreen === Screens.cashOutConfirmation">
                        <div class="container">
                            <form-container
                                @onSubmit="confirmCashOut"
                                container-classes="bg-light mx-0 mt-2 px-3"
                                ref="cashOutFormConfirmation"
                            >
                                <div class="text-start">
                                    <p
                                        v-html="
                                            $t(this.isCashOutViaBankStatement ? 'pages.cashOut.cashOutViaBankStmtUploadConfirmationSubTitle' : 'pages.cashOut.cashOutViaPlaidConfirmationSubTitle', {
                                                cashOutAmount: toFormattedUSD(this.cashOutAmount),
                                                accountEnding: this.last4AccountNumber,
                                            })
                                        "
                                    />
                                </div>
                                <div class="card border-0 p-2 px-3 mb-3 mt-3 bg-light">
                                    <div class="d-flex justify-content-between">
                                        <div class="text-start">
                                            <p class="mb-2">
                                                {{ $t('pages.cashOut.cashOutConfirmAmount') }}
                                            </p>
                                        </div>

                                        <div class="text-end">
                                            <p class="mb-2">
                                                {{ toFormattedUSD(cashOutAmount) }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div class="text-start">
                                            <p class="mb-0">
                                                {{ $t('pages.cashOut.cashOutConfirmationFee', { fee: getCashOutFee }) }}
                                            </p>
                                        </div>

                                        <div class="text-end">
                                            <p class="mb-0">
                                                {{ toFormattedUSD(cashOutWith2Percent - cashOutAmount) }}
                                            </p>
                                        </div>
                                    </div>

                                    <hr class="w-100">

                                    <div class="d-flex justify-content-between">
                                        <div class="text-start">
                                            <p class="fw-bold mb-0">
                                                {{ $t('pages.cashOut.cashOutConfirmationTotal') }}
                                            </p>
                                        </div>

                                        <div class="text-end">
                                            <p class="fw-bold mb-0">
                                                {{ toFormattedUSD(cashOutWith2Percent) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-show="shouldShouldShowLoanChoices"
                                    class="text-start"
                                >
                                    <div class="text-center text-muted section-header mb-2">
                                        {{ $t('components.amortizationLoanChoice.amortizationOptionsSectionHeader') }}
                                    </div>

                                    <div class="custom-control custom-radio custom-control-tight mb-4">
                                        <input
                                            type="radio"
                                            id="amortizationLoanTermOption"
                                            name="amortizationLoanTermOption"
                                            class="custom-control-input"
                                            data-testid="input-amortization-loan-term-option"
                                            :value="true"
                                            v-model="shouldAmortizeCashOut"
                                        >
                                        <label
                                            class="custom-control-label custom-control-label-tight"
                                            for="amortizationLoanTermOption"
                                            data-testid="label-amortization-loan-term-option"
                                        >{{
                                            $t('components.amortizationLoanChoice.amortizationOptionsTermText')
                                        }}</label>
                                        <p
                                            v-if="cashOutFee > 0"
                                            class="small text-muted"
                                        >
                                            {{ $t('components.amortizationLoanChoice.amortizationOptionsTermSubTextWithFee', { fee: getCashOutFee }) }}
                                        </p>
                                        <p
                                            v-else
                                            class="small text-muted"
                                        >
                                            {{ $t('components.amortizationLoanChoice.amortizationOptionsTermSubText') }}
                                        </p>
                                    </div>

                                    <div
                                        v-if="shouldAmortizeCashOut"
                                        class="ms-4 my-4 custom-line-height"
                                    >
                                        <div class="custom-control custom-radio custom-control-tight mb-6">
                                            <input
                                                type="radio"
                                                id="amortizationLoanTermOption1"
                                                name="amortizationLoanTermOption1"
                                                class="custom-control-input"
                                                :value="0"
                                                v-model="amortizationLoanTermSelectionId"
                                            >
                                            <label
                                                class="custom-control-label custom-control-label-tight w-100 mt-2"
                                                for="amortizationLoanTermOption1"
                                            >
                                                <amortization-loan-choice
                                                    :loan-term-in-months="parseFloat(amortizationLoanTermOptions[0].loanTermInMonths)"
                                                    :monthly-installment-in-dollars="parseFloat(amortizationLoanTermOptions[0].monthlyInstallmentInDollars)"
                                                    :fixed-finance-charges-per-month="parseFloat(amortizationLoanTermOptions[0].fixedFinanceChargesPerMonth)"
                                                    :fixed-principal-per-month="parseFloat(amortizationLoanTermOptions[0].fixedPrincipalPerMonth)"
                                                />
                                            </label>
                                        </div>

                                        <div class="custom-control custom-radio custom-control-tight h-auto">
                                            <input
                                                type="radio"
                                                id="amortizationLoanTermOption2"
                                                name="amortizationLoanTermOption2"
                                                class="custom-control-input"
                                                :value="1"
                                                v-model="amortizationLoanTermSelectionId"
                                                data-testid="amortization-loan-term-option-2-radio"
                                            >
                                            <label
                                                class="custom-control-label custom-control-label-tight w-100 mt-2"
                                                for="amortizationLoanTermOption2"
                                            >
                                                <amortization-loan-choice
                                                    :loan-term-in-months="parseFloat(amortizationLoanTermOptions[1].loanTermInMonths)"
                                                    :monthly-installment-in-dollars="parseFloat(amortizationLoanTermOptions[1].monthlyInstallmentInDollars)"
                                                    :fixed-finance-charges-per-month="parseFloat(amortizationLoanTermOptions[1].fixedFinanceChargesPerMonth)"
                                                    :fixed-principal-per-month="parseFloat(amortizationLoanTermOptions[1].fixedPrincipalPerMonth)"
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div class="custom-control custom-radio custom-control-tight">
                                        <input
                                            type="radio"
                                            id="amortizationLoanStandardOption"
                                            name="amortizationLoanStandardOption"
                                            class="custom-control-input"
                                            data-testid="input-amortization-loan-standard-option"
                                            :value="false"
                                            v-model="shouldAmortizeCashOut"
                                        >
                                        <label
                                            class="custom-control-label custom-control-label-tight"
                                            data-testid="amortization-loan-option"
                                            for="amortizationLoanStandardOption"
                                        >{{
                                            $t('components.amortizationLoanChoice.amortizationOptionsStandardText')
                                        }}</label>
                                        <p class="small text-muted">
                                            {{ $t('components.amortizationLoanChoice.amortizationOptionsStandardSubText', { apr }) }}
                                        </p>
                                    </div>
                                </div>

                                <base-button
                                    class="mt-8"
                                    :submitting="submitting"
                                    button-classes="btn btn-primary"
                                    data-testid="confirm-cash-out-button"
                                >
                                    {{ $t('pages.cashOut.confirmCashOutCta') }}
                                </base-button>
                            </form-container>
                        </div>
                        <learn-more class="mt-5">
                            <div class="fw-bolder mb-1">
                                {{ $t('pages.cashOut.fixedInstallmentVsRevolvingPlanTitle') }}
                            </div>
                            <div class="learn-more-content">
                                <div v-html="$t('pages.cashOut.fixedInstallmentVsRevolvingPlanText')" />
                            </div>
                        </learn-more>
                    </div>

                    <div v-else-if="currentScreen === Screens.cashOutSuccess">
                        <div
                            class="container"
                            data-testid="cash-out-success-container"
                        >
                            <div class="text-center mt-5">
                                <img
                                    src="@/assets/images/components/modal/balanceTransferConfirmation.svg"
                                    :alt="$t(this.isCashOutViaBankStatement ? 'pages.cashOut.cashOutViaBankStmtUploadTitle' : 'pages.cashOut.cashOutViaPlaidSuccessTitle')"
                                >
                            </div>
                            <h5
                                class="text-center fw-light my-1"
                                v-html="$t(this.isCashOutViaBankStatement ? 'pages.cashOut.cashOutViaBankStmtUploadTitle' : 'pages.cashOut.cashOutViaPlaidSuccessTitle')"
                            />

                            <div class="text-muted text-center mb-3">
                                <span
                                    v-html="
                                        $t(this.isCashOutViaBankStatement ? 'pages.cashOut.bankStatementReviewConfirmationSubTitle' : 'pages.cashOut.cashOutViaPlaidSuccessSubTitle', {
                                            cashOutAmount: toFormattedUSD(this.sentCashOutAmount),
                                            accountEnding: this.last4AccountNumber,
                                        })
                                    "
                                />
                            </div>

                            <div
                                v-show="shouldAmortizeCashOut"
                                class="text-muted text-center mb-3"
                            >
                                <span>For more details, you can <a
                                    @click="getDocumentAndOpen('AccountAgreement')"
                                    href="#"
                                >view your updated terms</a> which now include details on Fixed Monthly
                                    Installments.</span>
                            </div>
                            <form-button
                                class="mb-2"
                                :label="$t('pages.cashOut.doneCta')"
                                type="submit"
                                button-classes="btn btn-secondary"
                                @click="btnOnClickSuccessDone"
                                data-testid="cash-out-done-button"
                            />
                        </div>
                    </div>

                    <div v-else-if="currentScreen === Screens.bankAccountInfoForm">
                        <form-container
                            ref="bankAccountInformationFormContainer"
                            class="container"
                            @onSubmit="submitBankAccountInformation"
                        >
                            <h5>{{ $t('pages.cashOut.bankAccountInformationFormSubtitle') }}</h5>

                            <bank-account-info-form
                                v-model="bankAccountInfo"
                                ref="bankAccountInformationForm"
                                class="mt-3"
                            />

                            <base-button
                                class="mt-6"
                                button-classes="btn btn-primary"
                                :disabled="!isBankAccountInformationFormValid"
                            >
                                {{ $t('pages.cashOut.submitBankStatementsCta') }}
                            </base-button>
                        </form-container>
                    </div>

                    <div v-else-if="currentScreen === Screens.uploadBankStatement">
                        <form-container
                            ref="bankStatementForm"
                            class="container"
                            @onSubmit="uploadBankStatement"
                        >
                            <h5 class="mb-5">
                                {{ $t('pages.cashOut.uploadBankStatementFormSubTitle') }}
                            </h5>

                            <upload-button
                                class="mb-2"
                                :title="$t('pages.cashOut.uploadBankStatementOrVoidedCheckButton')"
                                @on-file-change="onSelectBankStatement"
                                :is-complete="hasBankStatementSelected"
                                incomplete-text="Add"
                                complete-text="✓ Added"
                                accept="image/*,application/pdf"
                            />

                            <img
                                src="../assets/check-bottom-clipped.jpg"
                                alt="Contact your bank for your routing and account number"
                                class="w-100 mb-3"
                            >

                            <base-button
                                button-classes="btn btn-primary"
                                :disabled="!isUploadBankStatementFormValid"
                                :submitting="isBankStatementUploading"
                                data-testid="upload-form-button-continue"
                            >
                                {{ $t('pages.cashOut.submitBankStatementsCta') }}
                            </base-button>
                        </form-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import { i18n } from '@/utils/i18n'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import PageHeader from '@/components/PageHeader'
    import BaseButton from '@/components/base/BaseButton'
    import FormFieldCurrency from '../components/base/FormFieldCurrency'
    import FormContainer from '@/components/base/FormContainer'
    import ListRow from '@/components/ListRow'
    import SectionHeader from '@/components/SectionHeader'
    import generic from '@/utils/generic'
    import format from '@/mixins/format'
    import {
        generateLegalDocument,
        getAmortizationLoanTerms,
        getLegalDocumentDownload,
        getSavedBankAccounts,
        hasNameMatch,
        plaidBankAccountDetails,
        postCashOut,
        postCashOutWithBankStatement,
        postRequestSingleUseToken,
        postUpdateDocumentUploadIdForCashOut,
        postVerifyLast4Ssn,
        registerCashOutUsingPlaid,
        registerCashOut,
        uploadDocument,
        registerNewBankAccountUsingBankStatementUpload,
        requestCashOutCancelation,
    } from '@/services/api'
    import FormButton from '@/components/base/FormButton'
    import { verifyPassword } from '@/services/avenAppApi'
    import {
        AmortizationSource,
        ApiRequireSingleUseToken,
        BankAccountTypes,
        MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS,
        PLAID_LINK_CUSTOMIZATION_NAME,
        PlaidAccountType,
        MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS,
        MAX_AMOUNT_ALLOWED_FOR_CASH_OUT_AFTER_PAYMENT,
        BANK_ROUTING_NUMBER_LENGTH,
    } from '@/data/constants'
    import { PlaidManager } from '@/utils/plaidManager'
    import FormFieldSsn from '@/components/base/FormFieldSsn'
    import FormField from '@/components/base/FormField'
    import UploadButton from '@/components/UploadButton'
    import { navigation } from '@/mixins/navigation'
    import { RouteNames } from '@/routes/router'
    import AmortizationLoanChoice from '@/components/AmortizationLoanChoice'
    import { isSafari } from '@/utils/parseUserAgents'
    import { AvenAccountStanding } from '@/store'
    import FeatureNotAvailable from '@/components/FeatureNotAvailable'
    import BaseRadio from '@/components/base/BaseRadio'
    import { BANK_ACCOUNT_NUMBER_MAX_LENGTH, BANK_ACCOUNT_NUMBER_MIN_LENGTH } from '../data/constants'
    import { PATHS } from '@/utils/paths'
    import BankAccountInfoForm from '@/components/BankAccountInfoForm'
    import LearnMore from '@/components/LearnMore'

    const addNewBankAccountOptionId = -1
    const AddNewBankAccountMethods = {
        plaid: 'plaid',
        bankStatements: 'bankStatements',
    }
    const Screens = {
        cashOutSummary: 0,
        passwordCheck: 1,
        last4SsnCheck: 2,
        createCashOutForm: 3,
        bankAccountInfoForm: 4,
        PickPlaidBankAccountTypeForm: 5,
        uploadBankStatement: 6,
        cashOutConfirmation: 7,
        cashOutSuccess: 8,
    }

    const screenFlow = {
        [Screens.passwordCheck]: {
            back: Screens.cashOutSummary,
        },
        [Screens.last4SsnCheck]: {
            back: Screens.passwordCheck,
        },
        [Screens.createCashOutForm]: {
            back: Screens.cashOutSummary,
        },
        [Screens.bankAccountInfoForm]: {
            back: Screens.createCashOutForm,
        },
        [Screens.PickPlaidBankAccountTypeForm]: {
            back: Screens.createCashOutForm,
        },
        [Screens.uploadBankStatement]: {
            back: Screens.createCashOutForm,
        },
        [Screens.cashOutConfirmation]: {
            back: Screens.createCashOutForm,
        },
    }

    export default {
        name: RouteNames.CASH_OUT,
        components: {
            LearnMore,
            BankAccountInfoForm,
            AmortizationLoanChoice,
            FormButton,
            FormFieldSsn,
            FormContainer,
            LoadingIndicator,
            PageHeader,
            BaseButton,
            FormFieldCurrency,
            ListRow,
            SectionHeader,
            'form-field': FormField,
            'upload-button': UploadButton,
            FeatureNotAvailable,
            'base-radio': BaseRadio,
        },
        mixins: [format, navigation], //returnToRoot comes from navigation
        data: function () {
            return {
                loading: true,
                loadingTitle: i18n.t('global.loadingMessage.loading'),
                errorText: '',
                submitting: false,
                currentScreen: Screens.cashOutSummary,
                Screens,
                screenFlow,
                password: '',
                last4Ssn: '',
                cashOutAmount: null, // this is a String
                sentCashOutAmount: null,
                cashOutTransactionSummary: '',
                singleUseToken: '',
                PlaidAccountType,
                accountType: PlaidAccountType.personal,
                plaidManager: new PlaidManager(this.onPlaidSuccess, this.onPlaidExit, PLAID_LINK_CUSTOMIZATION_NAME),
                plaidResponse: {},
                last4AccountNumber: '',
                savedSsn: null,
                savedPassword: null,
                bankAccountInfo: {
                    aciBankAccountType: BankAccountTypes[0].value,
                    routingNumber: '',
                    bankAccountNumber: '',
                    confirmBankAccountNumber: '',
                },
                bankStatementFile: null,
                uploadBankStatementId: null,
                isBankStatementUploading: false,
                shouldAmortizeCashOut: true,
                amortizationLoanTermOptions: [
                    {
                        apr: null,
                        loanAmount: null,
                        loanTermInMonths: null,
                        monthlyInstallmentInDollars: null,
                        fixedFinanceChargesPerMonth: null,
                        fixedPrincipalPerMonth: null,
                        amortizationLoanId: undefined,
                    },
                    {
                        apr: null,
                        loanAmount: null,
                        loanTermInMonths: null,
                        monthlyInstallmentInDollars: null,
                        fixedFinanceChargesPerMonth: null,
                        fixedPrincipalPerMonth: null,
                        amortizationLoanId: undefined,
                    },
                ],
                amortizationLoanTermSelectionId: 0,
                AvenAccountStanding,
                cashOutId: null,
                targetBankAccount: null,
                addNewBankAccountMethod: AddNewBankAccountMethods.plaid,
                AddNewBankAccountMethods,
                addNewBankAccountOptionId,
                selectedTargetAccount: addNewBankAccountOptionId,
                savedBankAccounts: [],
                externalBankAccountId: null,
                shouldShouldShowLoanChoices: this.enableAmortizationLoan,
                RouteNames,
                faqs: [
                    {
                        title: this.$t('pages.cashOut.whyCashOutTitle'),
                        content: this.$t('pages.cashOut.whyCashOutText'),
                    },
                    {
                        title: this.$t('pages.cashOut.areThereFeesTitle'),
                        content: this.$t('pages.cashOut.areThereFeesText'),
                    },
                    {
                        title: this.$t('pages.cashOut.howLongTitle'),
                        content: this.$t('pages.cashOut.howLongText'),
                    },
                    {
                        title: this.$t('pages.cashOut.howToPayBackTitle'),
                        content: this.$t('pages.cashOut.howToPayBackText'),
                    },
                    {
                        title: this.$t('pages.cashOut.limitForCashOutsTitle'),
                        content: this.$t('pages.cashOut.limitForCashOutText'),
                    },
                ],
            }
        },
        mounted: function () {
            if (!this.showCashOutFeature) {
                logger.info(`This account is ineligible for Cash Out feature`)
                return this.$router.replace('card')
            } else if (this.haveNotActivatedCardsBefore) {
                this.loading = false
            } else {
                this.initialLoad()
            }
        },
        beforeRouteLeave: function (to, from, next) {
            // Make sure we close the Plaid window when the user navigates away
            this.plaidManager.exit()
            next()
        },
        computed: {
            ...mapState(['balanceTransfer']),
            ...mapGetters([
                'customerInfo',
                'fullName',
                'availableCredit',
                'isPrimaryCard',
                'isCoApplicantCard',
                'showCashOutFeature',
                'cashOutTotalAmountWithFeeMultiplier',
                'existingCashOutTransfers',
                'cashOutFee',
                'isWebView',
                'enableAmortizationLoan',
                'apr',
                'avenAccountStanding',
                'haveNotActivatedCardsBefore',
                'totalPendingCashOutPlusBalanceTransfers',
                'madeRecentLargePayment',
            ]),
            targetAccountSelectOptions() {
                return this.savedBankAccounts.map((bankAccount) => ({
                    value: {
                        externalBankAccountId: bankAccount.externalBankAccountId,
                        last4AccountNumber: bankAccount.maskedAccountNumber,
                    },
                    label: `${bankAccount.accountType} (***${bankAccount.maskedAccountNumber})`,
                }))
            },
            showBack: function () {
                const virtualScreenHasBackButton = typeof this.screenFlow[this.currentScreen]?.back === 'number'
                return this.currentScreen === this.Screens.cashOutSummary || virtualScreenHasBackButton
            },
            showClose: function () {
                return this.currentScreen === this.Screens.cashOutSuccess
            },
            cashOutWith2Percent: function () {
                if (this.cashOutAmount) {
                    return parseFloat(this.cashOutAmount) * this.cashOutTotalAmountWithFeeMultiplier
                } else {
                    return ''
                }
            },
            getCashOutFee: function () {
                logger.info(`Cash Out Fee = ${this.cashOutFee}`)
                return this.toFormattedAprStripTrailingZerosAfterDecimalPoint(this.cashOutFee)
            },
            // this is of type number
            maxCashOutAmount() {
                // cashOut_amount + fee must be <= available_credit
                // i.e., in the worst case, if someone is using up all the available credit,
                // cashOut_amount * 1.02 = available_credit
                // => cashOut_amount = available_credit / 1.02
                return this.availableCredit / this.cashOutTotalAmountWithFeeMultiplier
            },
            getCashOutLimitMessage() {
                if (this.madeRecentLargePayment) {
                    return 'pages.cashOut.maxCashOutAfterLargePayment'
                } else {
                    return this.isTotalLimitHigherThanDailyLimit ? 'pages.cashOut.cashOutDailyLimitInfo' : 'pages.cashOut.cashOutLimitInfo'
                }
            },
            isTotalLimitHigherThanDailyLimit() {
                return this.maxCashOutAmount > MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS - this.totalPendingCashOutPlusBalanceTransfers
            },
            // this is of type String
            maxCashOutAmountPerTransaction: function () {
                let maxAllowedAmount = this.maxCashOutAmount
                // Cap Cash Out to MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS
                if (MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS * this.cashOutTotalAmountWithFeeMultiplier <= this.availableCredit) {
                    maxAllowedAmount = MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS
                }
                // Cap total Cash Out and BTs for the day
                const dailyAmountRemaining = MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS - this.totalPendingCashOutPlusBalanceTransfers
                logger.info(`There is ${dailyAmountRemaining} left for BTs and COs until any pending transfers clear`)
                if (maxAllowedAmount > dailyAmountRemaining) {
                    maxAllowedAmount = dailyAmountRemaining
                }
                // Cap cash outs after a large payment is made
                if (this.madeRecentLargePayment) {
                    logger.info(`Customer has made a recent large payment, cash outs are limited to $${MAX_AMOUNT_ALLOWED_FOR_CASH_OUT_AFTER_PAYMENT}`)
                    maxAllowedAmount = Math.min(MAX_AMOUNT_ALLOWED_FOR_CASH_OUT_AFTER_PAYMENT, this.maxCashOutAmount)
                }

                return maxAllowedAmount.toFixed(2) // returns a String
            },
            isInputAmountEmpty: function () {
                // verify that the input is empty, the actual validation happens in form-field-currency
                return !this.cashOutAmount
            },
            hasBankStatementSelected() {
                return Boolean(this.bankStatementFile)
            },
            isBankAccountInformationFormValid() {
                return (
                    this.bankAccountInfo.routingNumber.length === BANK_ROUTING_NUMBER_LENGTH &&
                    this.bankAccountInfo.bankAccountNumber.length >= BANK_ACCOUNT_NUMBER_MIN_LENGTH &&
                    this.bankAccountInfo.bankAccountNumber.length <= BANK_ACCOUNT_NUMBER_MAX_LENGTH &&
                    this.bankAccountInfo.bankAccountNumber === this.bankAccountInfo.confirmBankAccountNumber
                )
            },
            isUploadBankStatementFormValid() {
                return Boolean(this.hasBankStatementSelected)
            },
            isCashOutViaBankStatement() {
                return Boolean(this.uploadBankStatementId)
            },
        },
        watch: {
            currentScreen: function (val) {
                switch (val) {
                    case Screens.cashOutSummary:
                        window.logEvent('view_cash_out_summary')
                        break
                    case Screens.passwordCheck:
                        window.logEvent('view_cash_out_passcode_verification')
                        break
                    case Screens.last4SsnCheck:
                        window.logEvent('view_cash_out_ssn_4_verification')
                        break
                    case Screens.createCashOutForm:
                        window.logEvent('view_cash_out_form')
                        break
                    case Screens.plaidDataFetchLoading:
                        window.logEvent('plaid_data_fetch')
                        break
                    case Screens.uploadBankStatement:
                        window.logEvent('view_cash_out_upload_back_statement')
                        break
                    case Screens.cashOutConfirmation:
                        window.logEvent('view_cash_out_amount_confirmation')
                        break
                    case Screens.cashOutSuccess:
                        window.logEvent('view_cash_out_success')
                        break
                }
            },
        },
        methods: {
            initialLoad: async function () {
                this.loading = true
                await this.cashOutSummary()
                await this.tryInitializePlaidManager()
                await this.getSavedBankAccount()
                this.shouldShouldShowLoanChoices = this.enableAmortizationLoan
                this.loading = false
            },
            async getSavedBankAccount() {
                try {
                    const response = await getSavedBankAccounts()
                    this.savedBankAccounts = response.data.payload
                    logger.info(`savedBankAccounts: ${JSON.stringify(this.savedBankAccounts)}`)
                } catch (error) {
                    ApiErrorHandler(error)
                }
            },
            getDocumentAndOpen: async function (docType) {
                this.loadingTitle = i18n.t('global.loadingMessage.loading')
                this.loading = true
                logger.info(`Generating new legal doc: ${docType}...`)
                await generateLegalDocument(docType)
                logger.info(`Generating new legal doc: ${docType}...Done`)

                if (this.isWebView) {
                    logger.info(`native webview, get Account Agreement`)
                    const link = `/legal/document?docType=${docType}`
                    logger.info(`native webview, set window.location.href to ${link}`)
                    window.location.href = link
                } else {
                    logger.info(`browser, download account agreement`)
                    this.downloadingAccountAgreement = true
                    const response = await getLegalDocumentDownload(docType)
                    logger.info(`browser, download account agreement completed`)
                    const file = new Blob([response.data], { type: 'application/pdf' })
                    const fileURL = URL.createObjectURL(file)
                    if (isSafari()) {
                        window.location.href = fileURL
                    } else {
                        window.open(fileURL, '_blank')
                    }
                    this.downloadingAccountAgreement = false
                }
                this.loading = false
            },
            handleRowClick(id) {
                this.$router.push({
                    name: RouteNames.CASH_OUT_BY_ID,
                    params: {
                        id,
                    },
                })
            },
            tryInitializePlaidManager: async function () {
                if (this.plaidManager.alreadyInitialized()) {
                    return true
                }
                this.loading = true
                try {
                    const plaidInitialized = await this.plaidManager.init()
                    if (!plaidInitialized) {
                        logger.info('Plaid was unable to initialize')
                        this.errorText = i18n.tc('conversation.adBlockMessage')
                    }
                    return true
                } catch (error) {
                    ApiErrorHandler(error)
                    this.errorText = i18n.t('pages.cashOut.genericError')
                } finally {
                    this.loading = false
                }
                return false
            },
            cashOutSummary: async function () {
                try {
                    // get experian account info
                    logger.info('getting cash out balance transfer list')
                    await this.$store.dispatch('getExistingBalanceTransfers')
                } catch (error) {
                    ApiErrorHandler(error)
                    this.errorText = i18n.t('pages.cashOut.genericError')
                } finally {
                    this.loading = false
                }
            },
            verifyLast4Ssn: async function () {
                window.logEvent('click_cash_out_ssn_4_verification', { last4Ssn: this.last4Ssn })
                this.submitting = true

                try {
                    const response = await postVerifyLast4Ssn(this.last4Ssn)
                    if (!response.data.success) {
                        logger.info('Could not verify identity for single use token')
                        this.$refs.ssnForm.applyGeneralError(i18n.t('pages.cashOut.ssnInvalid'))
                        this.submitting = false
                        return false
                    }
                    this.savedSsn = this.last4Ssn
                    this.last4Ssn = ''
                    this.currentScreen = this.Screens.createCashOutForm
                    return true
                } catch (error) {
                    if (error.response?.status === 401) {
                        this.$refs.ssnForm.applyGeneralError(i18n.t('pages.cashOut.ssnInvalid'))
                    } else {
                        this.$refs.ssnForm.applyGeneralError(i18n.t('global.errors.generic'))
                    }
                    return false
                } finally {
                    this.submitting = false
                }
            },
            async getSingleUseToken({ isBankStatementMode }) {
                const singleUseTokenResponse = await postRequestSingleUseToken(
                    this.savedPassword,
                    this.savedSsn,
                    isBankStatementMode ? ApiRequireSingleUseToken.balanceTransferCashOutWithBankStatement : ApiRequireSingleUseToken.balanceTransferCashOut
                )
                if (!singleUseTokenResponse.data.success) {
                    this.$refs.ssnForm.applyGeneralError(i18n.t('pages.setPassword.generic'))
                    this.submitting = false
                    return false
                }

                this.singleUseToken = singleUseTokenResponse.data.payload.token
            },
            createCashOut: async function () {
                this.loading = true
                window.logEvent('click_cash_out_link_bank', { amount: this.cashOutAmount })

                // check which submit button triggered the event
                const isBankStatementMode = this.addNewBankAccountMethod === this.AddNewBankAccountMethods.bankStatements
                await this.tryGetAmortizationTerms()
                try {
                    if (this.selectedTargetAccount !== this.addNewBankAccountOptionId) {
                        logger.info(`Registering Cash Out`)
                        this.externalBankAccountId = this.selectedTargetAccount.externalBankAccountId
                        this.last4AccountNumber = this.selectedTargetAccount.last4AccountNumber
                        const registerCashOutResponse = await registerCashOut(this.cashOutAmount, this.externalBankAccountId, false)
                        this.cashOutId = registerCashOutResponse.data.payload.cashOutId
                        await this.getSingleUseToken({ isBankStatementMode: false })

                        this.currentScreen = Screens.cashOutConfirmation

                        this.loading = false
                    } else if (isBankStatementMode) {
                        logger.info(`Registering new bank account`)
                        const registerNewBankStatementResponse = await registerNewBankAccountUsingBankStatementUpload(
                            this.bankAccountInfo.aciBankAccountType,
                            undefined,
                            this.bankAccountInfo.bankAccountNumber,
                            this.bankAccountInfo.routingNumber
                        )
                        this.externalBankAccountId = registerNewBankStatementResponse.data.payload.externalBankAccountId

                        logger.info(`Registering Cash Out`)
                        const registerCashOutResponse = await registerCashOut(this.cashOutAmount, this.externalBankAccountId, true)
                        this.cashOutId = registerCashOutResponse.data.payload.cashOutId

                        this.currentScreen = Screens.uploadBankStatement
                        this.errorText = ''

                        this.loading = false
                    } else {
                        const isSuccessful = await this.tryInitializePlaidManager()
                        if (isSuccessful) {
                            this.errorText = ''
                            this.plaidManager.open()
                        }
                    }
                } catch (error) {
                    ApiErrorHandler(error)
                    this.errorText = i18n.t('pages.cashOut.genericError')
                }
            },
            confirmCashOut: async function () {
                window.logEvent('click_cash_out_amount_confirmation', {
                    amount: this.cashOutAmount,
                    amountPlusFee: this.cashOutWith2Percent,
                })
                this.submitting = true

                try {
                    let response
                    if (this.isCashOutViaBankStatement) {
                        response = await postCashOutWithBankStatement(
                            this.singleUseToken,
                            this.cashOutId,
                            this.amortizationLoanTermOptions[this.amortizationLoanTermSelectionId].amortizationLoanId,
                            this.shouldAmortizeCashOut
                        )
                        window.logEvent('trigger_manually_cashout_verify_bank_statement')
                    } else {
                        logger.log(`Plaid response before posting cash out: ${JSON.stringify(this.plaidResponse)}`)
                        response = await postCashOut(
                            this.singleUseToken,
                            this.cashOutId,
                            this.amortizationLoanTermOptions[this.amortizationLoanTermSelectionId].amortizationLoanId,
                            this.shouldAmortizeCashOut
                        )
                    }

                    if (!response.data.success) {
                        logger.error(`postCashOut failed with ${response.data.error}`)
                    } else {
                        logger.info(`postCashOut successful. ${JSON.stringify(response.data)}`)
                    }
                } catch (error) {
                    logger.error(`postCashOut failed`, null, error)
                } finally {
                    this.currentScreen = Screens.cashOutSuccess
                    this.sentCashOutAmount = this.cashOutAmount
                    this.cashOutAmount = null
                    this.last4Ssn = ''
                    this.password = ''
                    this.bankAccountInfo.aciBankAccountType = BankAccountTypes[0].value
                    this.bankAccountInfo.routingNumber = ''
                    this.bankAccountInfo.bankAccountNumber = ''
                    this.bankStatementFile = null
                    this.cashOutId = null
                    this.amortizationLoanId = null
                    this.externalBankAccountId = null
                    this.submitting = false
                }
            },
            convertDateTimeFormat: function (date, options) {
                return generic.convertDateTimeFormat(date, options)
            },
            handleOnClose: async function () {
                this.errorText = ''
                if (this.cashOutId && (this.currentScreen === Screens.createCashOutForm || this.currentScreen === Screens.uploadBankStatement || this.currentScreen === Screens.cashOutConfirmation)) {
                    logger.info(`Customer is navigating away from currentScreen[${this.currentScreen}] before submitting Cash Out request. Marking the Cash Out id ${this.cashOutId} as canceled`)
                    await requestCashOutCancelation(this.cashOutId)
                    this.cashOutId = null
                    this.amortizationLoanId = null
                    this.externalBankAccountId = null
                }

                if (this.currentScreen === Screens.cashOutSuccess || this.currentScreen === Screens.cashOutSummary) {
                    await this.returnToRoot()
                } else {
                    this.currentScreen = this.screenFlow[this.currentScreen].back
                }
            },
            forgotPassword: function () {
                window.logEvent('click_cash_out_reset_passcode')
                this.$router.push({
                    name: RouteNames.SET_PASSWORD,
                    params: {
                        resetDuringSecureFeature: true,
                    },
                })
            },
            passwordVerification: async function () {
                const isValid = await this.$refs.passwordForm.$refs.observer.validate()
                if (!isValid) {
                    return
                }
                try {
                    this.submitting = true
                    const response = await verifyPassword(this.password)
                    this.$refs.passwordForm.clearErrorMessage()
                    this.savedPassword = this.password
                    this.password = ''
                    const data = response.data
                    if (!data.success) {
                        this.$refs.passwordForm.applyGeneralError(data.error)
                        logger.error(`login with Password fail :${data.error}`)
                        return false
                    }
                    this.currentScreen = this.Screens.last4SsnCheck
                    this.$nextTick(() => {
                        this.$refs.ssnField.setFocus()
                    })
                    return true
                } catch (error) {
                    if (error.response?.status === 401) {
                        if (error.response.data.error === 'INCORRECT_PASSCODE_ERROR') {
                            this.$refs.passwordForm.applyGeneralError(i18n.t('pages.passwordLogin.error.incorrectPassword'))
                        }
                    } else if (error.response?.status === 404) {
                        this.$refs.passwordForm.applyGeneralError(i18n.t('pages.passwordLogin.error.accountNotFound'))
                    } else {
                        this.$refs.passwordForm.applyGeneralError(i18n.t('global.errors.generic'))
                    }
                    this.password = ''
                    this.$refs.passwordInput.setFocus()
                    return false
                } finally {
                    this.submitting = false
                }
            },
            btnOnClickNewCashOut: function () {
                window.logEvent('click_cash_out_new_cash_out')
                this.currentScreen = Screens.passwordCheck
            },
            btnOnClickSuccessDone: async function () {
                window.logEvent('click_cash_out_success_done')
                this.shouldAmortizeCashOut = false
                this.loading = true
                await this.$store.dispatch('getExistingBalanceTransfers')
                await this.$store.dispatch('updateAccountOverview')
                this.loading = false
                this.currentScreen = Screens.cashOutSummary
            },
            onPlaidSuccess: async function (publicToken, account, institution) {
                // clear bank statement in case that there's one so we can detect more easily what verification method is being used
                this.uploadBankStatementId = null
                logger.info(`successfully connected to bank and received account ${JSON.stringify(account)} institution ${JSON.stringify(institution)}`)
                this.plaidResponse = { publicToken, account, institution }

                this.loading = true
                this.loadingTitle = i18n.t('pages.bankConnect.loadingTitle')

                try {
                    // 1. Wait for plaid asset report fetch
                    const plaidCompletionSuccess = await this.plaidManager.completePlaidFetch(this.plaidResponse.publicToken, this.plaidResponse.account.id, this.plaidResponse.institution.name)
                    if (!plaidCompletionSuccess) {
                        this.errorText = i18n.t('pages.cashOut.plaidGenericError')
                        this.currentScreen = Screens.bankAccountInfoForm
                        return false
                    }

                    // 2. Retrieve bank details viz., ACH, Routing and account type. Backend shall parse these details from
                    // plaid_data_report table.
                    const plaidAccountDetails = await plaidBankAccountDetails(this.plaidResponse.account.id)
                    if (!plaidAccountDetails.data.success) {
                        this.errorText = i18n.t('pages.cashOut.plaidGenericError')
                        this.currentScreen = Screens.bankAccountInfoForm
                        return false
                    }
                    this.last4AccountNumber = plaidAccountDetails.data.payload.last4AccountNumber

                    const hasNameMatchResponse = await hasNameMatch()
                    if (!hasNameMatchResponse.data.success) {
                        this.errorText = i18n.t('pages.cashOut.plaidGenericError')
                        this.currentScreen = Screens.bankAccountInfoForm
                        return false
                    }

                    await this.getSingleUseToken({ isBankStatementMode: false })
                    this.last4AccountNumber = plaidAccountDetails.data.payload.last4AccountNumber
                    this.currentScreen = this.Screens.PickPlaidBankAccountTypeForm
                } catch (e) {
                    this.errorText = i18n.t('pages.cashOut.genericError')
                    ApiErrorHandler(e)
                } finally {
                    this.plaidManager.destroy()
                    this.loading = false
                    this.loadingMessage = i18n.t('global.loadingMessage.loading') // reset loading message
                }
            },
            onPlaidExit: function (error, metadata) {
                logger.info(`onPlaidExit error: ${JSON.stringify(error)} metadata: ${JSON.stringify(metadata)}`)
                this.errorText = i18n.t('pages.bankConnect.tryAgain')
                this.loading = false
                this.submitting = false
            },
            submitBankAccountInformation: async function () {
                this.errorText = ''
                // At this point we have all information we need for this Cash Out transaction except the bank statement upload.
                try {
                    this.submitting = true
                    logger.info(`Registering new bank account`)
                    const registerNewBankStatementResponse = await registerNewBankAccountUsingBankStatementUpload(
                        this.bankAccountInfo.aciBankAccountType,
                        undefined,
                        this.bankAccountInfo.bankAccountNumber,
                        this.bankAccountInfo.routingNumber
                    )
                    this.externalBankAccountId = registerNewBankStatementResponse.data.payload.externalBankAccountId

                    logger.info(`Registering Cash Out`)
                    const registerCashOutResponse = await registerCashOut(this.cashOutAmount, this.externalBankAccountId, true)
                    this.cashOutId = registerCashOutResponse.data.payload.cashOutId
                } catch (e) {
                    logger.error('Bank account information submission after unsuccessful Plaid linking failed', null, e)
                } finally {
                    this.submitting = false
                }
                this.currentScreen = Screens.uploadBankStatement
            },
            uploadBankStatement: async function () {
                logger.info('Adding bank account for cash-out')
                try {
                    this.errorText = ''
                    this.isBankStatementUploading = true
                    this.last4AccountNumber = this.bankAccountInfo.bankAccountNumber.substr(-4)

                    const uploadBankStatementResponse = await uploadDocument(this.bankStatementFile, 'bankStatementForCashOut', 1)
                    if (!uploadBankStatementResponse.data.success) {
                        let errorTextKey = 'pages.cashOut.genericError'
                        if (uploadBankStatementResponse.data.error === 'ERROR_UNSUPPORTED_DOCUMENT_TYPE') {
                            logger.info(`Bank Statement upload, Customer uploaded unsupported file format`)
                            errorTextKey = 'pages.cashOut.unsupportedFileFormatError'
                        } else {
                            logger.info(`Bank Statement upload failed, ${JSON.stringify(uploadBankStatementResponse)}`)
                        }
                        this.isBankStatementUploading = false
                        this.errorText = i18n.t(errorTextKey)
                        return false
                    }
                    this.uploadBankStatementId = uploadBankStatementResponse.data.payload.documentUploadId
                    logger.info(`Successfully uploaded Bank Statement with the ID: ${this.uploadBankStatementId}`)

                    const updateDocumentResponse = await postUpdateDocumentUploadIdForCashOut(this.cashOutId, this.uploadBankStatementId)
                    if (!updateDocumentResponse.data.success) {
                        this.isBankStatementUploading = false
                        this.errorText = i18n.t('pages.cashOut.genericError')
                        return false
                    }

                    await this.getSingleUseToken({ isBankStatementMode: true })

                    this.currentScreen = Screens.cashOutConfirmation
                } catch (e) {
                    logger.error('onBankStatementSubmit failed', null, e)
                } finally {
                    this.isBankStatementUploading = false
                    this.bankStatementFile = null
                }
            },
            onSelectBankStatement(fileList) {
                this.bankStatementFile = fileList[0]
            },
            submitPlaidBankAccountType: async function () {
                this.submitting = true
                // At this point we have all information we need for this Cash Out transaction
                try {
                    logger.info(`Registering Cash Out`)
                    const registerCashOutResponse = await registerCashOutUsingPlaid(this.cashOutAmount, this.accountType, this.plaidResponse.institution.name)
                    logger.info(`${JSON.stringify(registerCashOutResponse.data)}`)
                    this.cashOutId = registerCashOutResponse.data.payload.cashOutId
                    this.currentScreen = Screens.cashOutConfirmation
                } catch (e) {
                    ApiErrorHandler(e)
                    this.errorText = i18n.t('pages.cashOut.genericError')
                } finally {
                    this.submitting = false
                }
            },
            tryGetAmortizationTerms: async function () {
                try {
                    if (this.enableAmortizationLoan) {
                        const amortizationLoanTermsResponse = await getAmortizationLoanTerms(this.cashOutAmount, AmortizationSource.CASH_OUT)
                        if (!amortizationLoanTermsResponse.data.success) {
                            this.shouldShouldShowLoanChoices = false
                        } else {
                            this.amortizationLoanTermOptions = amortizationLoanTermsResponse.data.payload?.amortizationLoanOptions
                        }
                    } else {
                        this.shouldShouldShowLoanChoices = this.enableAmortizationLoan
                    }
                } catch (e) {
                    logger.error(`getAmortizationTerms failed`, null, e)
                    this.shouldShouldShowLoanChoices = false
                    this.amortizationLoanId = null
                }
            },
            onClickCardActivationLink: function () {
                if (this.isWebView && !this.isSingleWebView) {
                    window.location.href = PATHS.MOBILE_REDIRECT.CARD_ACTIVATION
                } else {
                    this.$router.push({
                        name: 'CardActivation',
                    })
                }
            },
        },
    }
</script>

<style scoped>
    .cash-out-limit-info {
        font-size: 0.75rem;
    }
    .error-link {
        text-decoration: underline;
        color: #cf8100;
    }
</style>
