<template>
    <label
        :for="id"
        class="custom-radio"
        :class="{ 'radio-disabled': disabled }"
    >
        <span
            class="label"
            :class="labelClasses"
        >{{ label }}</span>
        <span class="input">
            <span
                v-show="valueLabel"
                class="me-1"
            >{{ valueLabel }}</span>
            <input
                :id="id"
                type="radio"
                :name="id"
                :value="id"
                :checked="id === value"
                :disabled="disabled"
                :class="inputClasses"
                @input="$emit('input', $event.target.value)"
                :data-testid="dataTestid"
            >
            <span class="control" />
        </span>
        <span
            v-if="labelRight"
            class="label ms-2 flex-grow-1 text-start"
            :class="labelClasses"
        >{{ labelRight }}</span>
    </label>
</template>

<script>
    export default {
        name: 'BaseRadio',
        inheritAttrs: false,
        props: {
            // `id` will also populate `name` and `value`
            id: {
                type: String,
                required: true,
            },
            label: {
                type: [String, Number, Array],
                default: '',
            },
            valueLabel: {
                type: String,
                default: '',
            },
            labelRight: {
                type: String,
            },
            disabled: {
                type: Boolean,
            },
            containerClasses: {
                type: [String, Object, Array],
            },
            value: {
                type: [String, Number],
            },
            labelClasses: {
                type: [String, Object, Array],
            },
            inputClasses: {
                type: [String, Object, Array],
            },
            dataTestid: {
                type: String,
            },
        },
    }
</script>

<style lang="scss" scoped>
    .radio-disabled {
        opacity: 0.2;

        span.control {
            border-color: $primary;
        }
    }
</style>
