<template>
    <div>
        <div class="d-flex">
            <div>
                <div class="card-shadow custom-line-height">
                    {{ $t('components.amortizationLoanChoice.amortizationOptionTitle', { numYears: (loanTermInMonths / 12).toFixed(0) }) }}
                </div>
                <div
                    class="small"
                    v-html="$t('components.amortizationLoanChoice.amortizationOptionSubTitle', { numPayments: loanTermInMonths })"
                />
            </div>
            <div class="pill me-0 float-right">
                {{ toFormattedUSD(monthlyInstallmentInDollars) }}
            </div>
        </div>

        <div class="mt-1">
            <div class="text-muted small d-flex flex-row justify-content-between">
                <div class="text-start">
                    {{ $t('components.amortizationLoanChoice.amortizationOptionsSubText1') }}
                </div>
                <div class="text-end me-0">
                    {{ toFormattedUSD(fixedPrincipalPerMonth) }}
                </div>
            </div>
            <div class="text-muted small d-flex flex-row justify-content-between">
                <div class="text-start">
                    {{ $t('components.amortizationLoanChoice.amortizationOptionsSubText2') }}
                </div>
                <div class="float-right text-end me-0">
                    {{ toFormattedUSD(fixedFinanceChargesPerMonth) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import format from '@/mixins/format'

    export default {
        name: 'AmortizationLoanChoice',
        mixins: [format],
        props: {
            loanTermInMonths: {
                type: Number,
                required: true,
            },
            monthlyInstallmentInDollars: {
                type: Number,
                required: true,
            },
            fixedPrincipalPerMonth: {
                type: Number,
                required: true,
            },
            fixedFinanceChargesPerMonth: {
                type: Number,
                required: true,
            },
        },
    }
</script>

<style>
    .custom-line-height {
        line-height: 1;
    }
</style>
