<template>
    <div class="text-center container">
        <img
            src="@/assets/images/global/credit_card_sad.svg"
            alt="feature not available"
            class="m-auto"
        >
        <h5
            class="text-center fw-light my-1"
            v-html="$t('components.featureNotAvailable.title')"
        />

        <div class="text-muted text-center mb-3">
            <span v-html="$t('components.featureNotAvailable.subTitle')" />
        </div>

        <base-button
            button-classes="btn btn-secondary"
            @click="contactUs"
        >
            {{ $t('components.featureNotAvailable.contactUsBtnCta') }}
        </base-button>
    </div>
</template>

<script>
    import BaseButton from '@/components/base/BaseButton'
    import { contact_info } from '@/utils/contact-info-dictionary'

    export default {
        name: 'FeatureNotAvailable',
        components: {
            'base-button': BaseButton,
        },
        methods: {
            contactUs() {
                location.href = `tel:${contact_info.phone}`
            },
        },
    }
</script>

<style scoped></style>
