<template>
    <div>
        <validation-provider
            mode="eager"
            v-slot="{ errors }"
            :vid="name"
            :rules="ssnValidation"
        >
            <div class="input">
                <label
                    v-if="label"
                    :for="name"
                >
                    {{ label }}
                </label>
                <cleave
                    ref="formFieldInput"
                    autocomplete="off"
                    data-private
                    type="tel"
                    class="form-control"
                    form-classes="form-classes"
                    :id="name"
                    :options="options"
                    :class="[errors[0] ? 'is-invalid' : null, showLockIcon ? 'has-lock-icon' : null]"
                    :name="name"
                    :placeholder="placeholder"
                    :value="value"
                    v-model="cleaveValue"
                />
                <div class="invalid-feedback text-start">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputCleaveMixin, formInputMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormSsnInput',
        mixins: [formInputMixin, formInputCleaveMixin],
        props: {
            lastFourOnly: { type: Boolean, default: false },
            showLockIcon: { type: Boolean, default: false },
        },
        computed: {
            ssnValidation() {
                if (this.lastFourOnly) {
                    return 'required|min:4|max:4'
                } else {
                    return 'required|min:9|max:9'
                }
            },

            options() {
                if (this.lastFourOnly) {
                    return {
                        numericOnly: true,
                        blocks: [4],
                    }
                } else {
                    return {
                        numericOnly: true,
                        blocks: [3, 2, 4],
                        delimiters: ['-'],
                    }
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/base/formField';
</style>
