import { logger } from '@/utils/logger'
import { plaidReportFetchState, postCashOut, startPlaidReportFetch } from '@/services/api'
import store from '@/store'
import assert from 'assert'
import { hideNativeNavBar, showNativeNavBar } from '@/utils/mobileUtils'

const PLAID_SRC = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js'

export class PlaidManager {
    private plaidHandler
    private readonly onPlaidSuccess
    private onPlaidExit
    private linkCustomizationName
    private isInitialized: boolean

    constructor(onPlaidSuccess, onPlaidExit, linkCustomizationName) {
        this.onPlaidSuccess = onPlaidSuccess
        this.onPlaidExit = onPlaidExit
        this.linkCustomizationName = linkCustomizationName
        this.isInitialized = false
    }

    public init = async (): Promise<boolean> => {
        logger.info(`bankConnect headless`)
        // Load the Plaid script async
        await this.loadPlaidScript()
        this.isInitialized = true
        return this.setupPlaid()
    }

    public alreadyInitialized = () => this.isInitialized

    private setupPlaid = (): boolean => {
        try {
            this.plaidHandler = this.createPlaidHandler(this.onPlaidSuccess, this.onPlaidExit)
            logger.info('Plaid handler initialized')
            return true
        } catch (error) {
            logger.error('Plaid initialize failed due to ad blocker, error:', null, error)
        }
        return false
    }

    public open = (): void => {
        logger.info('Opening plaid...')
        assert(this.isInitialized, 'must reinitialize plaid before calling open')
        this.plaidHandler.open()
        this.isInitialized = false
        hideNativeNavBar()
    }

    public exit = (): void => {
        logger.info('Making sure to exit plaid if its open...')
        this.plaidHandler?.exit()
    }

    public destroy = (): void => {
        logger.info('Removing any DOM artifacts created by Plaid...')
        this.plaidHandler?.destroy()
    }

    private createPlaidHandler = (onSuccess, onExit) => {
        return window.Plaid.create({
            clientName: 'Aven',
            countryCodes: ['US'],
            linkCustomizationName: this.linkCustomizationName,
            env: process.env.VUE_APP_PLAID_ENV,
            key: process.env.VUE_APP_PLAID_PUBLIC_KEY,
            product: ['auth', 'assets'],
            onEvent: function (eventName, metadata) {
                logger.info(`plaid link onEvent metaData: ${JSON.stringify(metadata)}`)
                // log all events provided by plaid. note that these won't trigger until the plaid screen is closed.
                const internalEventName = `plaid_internal_${eventName.toLowerCase()}`
                window.logEvent(internalEventName, metadata)
            },
            onSuccess: function (publicToken, metadata) {
                showNativeNavBar()
                window.logEvent('plaid_on_success', { ['plaid_bank_name']: metadata.institution.name })
                if (onSuccess) {
                    logger.info(`plaid link onSuccess metaData: ${JSON.stringify(metadata)}`)
                    onSuccess(publicToken, metadata.account, metadata.institution)
                } else {
                    logger.info(`plaid link onSuccess event with no onSuccess handler metaData: ${JSON.stringify(metadata)}`)
                }
            },
            onExit: function (error, metadata) {
                showNativeNavBar()
                if (error) {
                    logger.info(`plaid link onExit returned error: ${JSON.stringify(error)}`)
                    window.logEvent('plaid_internal_error', { ...error, ...metadata })
                }
                if (onExit) {
                    logger.info(`plaid link onExit metaData: ${JSON.stringify(metadata)}`)
                    onExit(error, metadata)
                } else {
                    logger.info(`plaid link onExit event with no onExit handler metaData: ${JSON.stringify(metadata)}`)
                }
            },
            onLoad: function () {
                logger.info('plaid onLoad')

                // FIXME: Remove this style override when plaid iOS and Android SDKs are integrated.
                // This fix is to make sure that the plaid iframe is fully visible despite the tab at the bottom on iOS and Android
                if (store.getters.isWebView) {
                    logger.info('plaid onLoad isWebView')
                    const iframes: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('plaid-link-iframe') as HTMLCollectionOf<HTMLElement>
                    for (const iframe of iframes) {
                        logger.info(`Setting iframe height of ${iframe} to 80%`)
                        iframe.style.setProperty('min-height', '96%', 'important')
                    }
                }
            },
        })
    }

    private loadPlaidScript = async (): Promise<void> => {
        for (const x of document.head.getElementsByTagName('script')) {
            if (x.src === PLAID_SRC) {
                logger.info('Plaid already loaded')
                return
            }
        }
        logger.info('Plaid script not found in DOM. Attaching...')

        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.onload = () => {
                logger.info('Plaid script loaded')
                resolve()
            }
            script.setAttribute('src', PLAID_SRC)
            logger.info('Waiting for Plaid script to load...')
            document.head.appendChild(script)
        })
    }

    // Code copied from aven.com
    public completePlaidFetch = async (plaidPublicToken: string, accountId: string, institutionInfo: string): Promise<boolean> => {
        const response = await startPlaidReportFetch(plaidPublicToken, accountId, institutionInfo)
        logger.info(`startPlaidReportFetch response.data: ${JSON.stringify(response.data)}`)

        if (response.data.success) {
            logger.info(`submitted bank account`)
            return await this.pollForPlaidReportCompletion()
        } else {
            logger.error(`failed to start plaid fetch`)
        }
        return false
    }

    private pollForPlaidReportCompletion = async (): Promise<boolean> => {
        let retryCount = 0
        logger.info(`Beginning to poll for plaidReport fetch completion`)
        while (retryCount < 150) {
            // ~ 2000ms * 150 retries = poll for 5 min
            const plaidReportState = await plaidReportFetchState()

            if (plaidReportState.data.payload.plaidReportState !== 'complete') {
                if (plaidReportState.data.payload.isFetched) {
                    break
                }
                retryCount++
                logger.info(`PlaidReport NOT READY, attempt#: ${retryCount}`)
                await new Promise((r) => setTimeout(r, 2000))
                continue
            }
            logger.info(`PlaidReport fetch is complete!`)

            // Plaid Asset Fetch Status is set to StatusCodes.OK.toString() only if the asset report
            // fetch was successful.
            return plaidReportState.data.payload.plaidAssetFetchStatus === '200'
        }
        logger.error(`PlaidReport fetch did not appear to complete successfully!`)
        return false
    }
}
