<template>
    <div class="bg-learn-more px-3 py-4 text-start">
        <div class="d-flex mb-3">
            <img
                class="me-1"
                src="@/assets/graduation-cap-icon.svg"
                :alt="$t('components.cashOutInformation.graduationCapAlt')"
            >
            <h6 class="small fw-bolder text-uppercase my-auto space-letters">
                {{ $t('components.learnMore.title') }}
            </h6>
        </div>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'LearnMore',
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/learnMore';
</style>
